<template>
  <div style="height:100%;" class="d-flex justify-space-between">
    <!-- 合同左侧小图预览 -->
    <!-- <div class="content-left">
      <div class="content-section bg-white ma-24">
        <div class="pa-14 text-left content-con" v-html="content"></div>
      </div>
    </div> -->
    <!-- 合同主体内容"-->
    <div class="bg-white content-contarct mt-16">
      <div v-html="content"></div>
      <a-button
        v-if="signTime === null"
        type="primary"
        class="content-sign-btn"
        @click="handleSingBtn"
        >签署合同</a-button
      >
    </div>
    <!-- 合同签署 -->
    <div
      class="bg-white  content-sign pt-16"
      style="width:363px;position:relative"
      v-if="isSign"
    >
      <div class="px-24">
        <!-- 签署的提示 -->
        <div>
          <h3 style="line-height:20px;font-size:14px;">签署内容</h3>
          <p
            class="mt-8 mb-24"
            style="color:#C1C1C1;font-size:12px;line-height:14px;"
          >
            请在输入框内填入对应的信息，若没有签署内容，可点击按钮直接签署合同
          </p>
        </div>
        <a-form layout="vertical">
          <a-form-item
            v-for="(item, index) in stateRef.placeholder_array"
            :key="index"
            :label="item.label"
            :v-bind="item.key"
          >
            <a-input
              :placeholder="`请输入 ${item.value}`"
              v-model:value.trim="item.value"
              @blur="handleBlur(item.value, index)"
            ></a-input>
            <span
              v-if="formRefShow && formRefIndex === index"
              style="color:#E72832"
              >{{ item.label }} 不能为空</span
            >
          </a-form-item>
        </a-form>
        <div class="text-center contract-sign-btn">
          <a-button
            class="mb-16"
            @click="handleForBack"
            v-if="stateRef.placeholder_array.length > 0"
            >返回</a-button
          >
          <a-button type="primary" class="ml-16" @click="handlePerviewSign"
            >签署</a-button
          >
        </div>
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      :width="404"
      cancelText="离开"
      okText="留下"
      @cancel="handleForLeave"
      @ok="visible = false"
      :closable="false"
    >
      <div class="text-center">
        <div class="d-flex align-center justify-center">
          <Icon name="icontishileiicon" width="16" height="16"></Icon>
          <p class="ml-8 fz-16 fw-500 mt-2" style="color:#3C4E64">提示</p>
        </div>
        <p class="mt-10 mb-16">离开页面后，已填内容无法保存，是否确认离开</p>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="showWaitBox"
      :footer="null"
      :width="404"
      :closable="false"
    >
      <div class="text-center">
        电子合同签署已发起成功，将在15秒后完成签署，
        {{ waitTime }}秒后，返回至合同列表……
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Message } from "ant-design-vue";
import { useForm } from "@ant-design-vue/use";
import { getManagerForm, getManagerFormRules } from "../constants/index";

import { useContractPreviewApi, useContractSignApi } from "@/apis/contract.js";

export default defineComponent({
  setup() {
    const id = ref();
    const content = ref();
    const show = ref(true);
    const signTime = ref();
    const route = useRoute();
    const router = useRouter();
    const isSign = ref(+route.query.isPreview === 0 ? false : true);
    const visible = ref(false);
    const stateNameKey = ref();
    const stateSignatoryKey = ref();
    const formRefShow = ref(false);
    const formRefIndex = ref(0);

    // form表单对象
    const stateRef = reactive(getManagerForm());
    // form表单对象验证
    const rules = ref();

    const contractPreview = useContractPreviewApi();
    const contractSign = useContractSignApi();

    (async () => {
      const data = await contractPreview({
        company_id: route.query.companyId,
        id: route.query.id
      });
      content.value = data.content;
      id.value = data.id;
      if (isSign.value) {
        signTime.value = "";
      } else {
        signTime.value = data.sign_time;
      }
      data.placeholder_array.map(item => {
        //将value保存再label中后 置空value
        item.label = item.value;
        item.value = "";
        stateRef[item.value] = item.value;
      });
      stateRef.placeholder_array = data.placeholder_array;
      console.log("padcss", stateRef.placeholder_array);
    })();

    setTimeout(() => {
      rules.value = getManagerFormRules(stateRef.placeholder_array);
    }, 1000);
    const { resetFields, validate, validateInfos } = useForm(stateRef, rules);

    const handleForLeave = () => {
      resetFields();
      router.push({
        path: "service-contract"
      });
    };

    const handleForBack = () => {
      console.log("stateRef", stateRef);
      if (stateRef.signatory === null && stateRef.name === null) {
        router.push({
          path: "service-contract"
        });
      } else {
        visible.value = true;
      }
    };

    const handlePerviewSign = async () => {
      try {
        await validate();
        formRefIndex.value = -1;
        formRefShow.value = false;
        stateRef.placeholder_array.map((item, index) => {
          console.log("占位符item", item);
          if (item.value === "" || item.length === 0) {
            formRefIndex.value = index;
            formRefShow.value = true;
          } else {
            delete item.label;
          }
        });
        if (formRefShow.value) return;
        const params = {
          id: id.value,
          // signatory: stateRef.signatory,
          placeholder_array: stateRef.placeholder_array
        };
        await contractSign(params);
        // Message.success("签署成功");
        //延迟窗口
        await waitTimeBegin();
        router.push({
          path: "service-contract"
        });
      } catch (error) {
        console.log(error);
      }
    };
    //签署延迟窗口
    const waitTime = ref(0);
    const showWaitBox = computed(() => waitTime.value > 0);
    const waitTimeBegin = () => {
      return new Promise(function(resolve) {
        waitTime.value = 15;
        const intervalId = setInterval(() => {
          waitTime.value--;
          if (waitTime.value === 0) {
            clearInterval(intervalId);
            resolve();
          }
        }, 1000);
      });
    };

    const handleSingBtn = () => {
      isSign.value = true;
      signTime.value = "";
    };

    const handleSign = async () => {
      try {
        Message.success("签署成功");
      } catch (error) {
        console.log(error);
      }
    };

    const handleBlur = (value, index) => {
      if (value.length === 0) {
        formRefIndex.value = index;
        formRefShow.value = true;
      } else {
        formRefIndex.value = -1;
        formRefShow.value = false;
      }
    };

    const handleClose = () => {
      router.push({
        path: "service-contract"
      });
    };

    return {
      id,
      show,
      rules,
      isSign,
      visible,
      content,
      waitTime,
      signTime,
      stateRef,
      showWaitBox,
      handleBlur,
      handleSign,
      formRefShow,
      formRefIndex,
      resetFields,
      handleClose,
      handleSingBtn,
      stateNameKey,
      validateInfos,
      handleForBack,
      handleForLeave,
      handlePerviewSign,
      stateSignatoryKey
    };
  }
});
</script>
<style lang="less" scoped>
.contract-check {
  padding: 32px 120px 24px 120px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
}
.contract-content {
  padding: 24px 32px;
  border: 1px solid #808080;
  border-radius: 5px;
  text-align: left;
}
.content-left {
  width: 144px;
  height: 100%;
  border-right: 1px solid #d4d4d4;
  background: #f8f9fb;
}
.content-section {
  position: relative;
  width: 96px;
  height: 136px;
  border: 1px solid #1684fb;
  cursor: pointer;
}
.content-con {
  font-size: 12px;
  transform: scale(0.5);
  position: absolute;
  top: -40%;
  left: -32px;
  right: 0px;
  bottom: 20px;
  width: 160px;
  height: 240px;
  overflow: hidden;
}
.content-contarct {
  min-width: 820px;
  max-width: calc(100vw - 1000px);
  height: 100%;
  padding: 56px 85px;
  margin: 0 auto;
  overflow: auto;
  position: relative;
}
.content-sign-btn {
  position: fixed;
  bottom: 24px;
  left: 50%;
  /* right: 0px;
  margin: 0 auto; */
  width: 166px;
  height: 32px;
}
.content-sign {
  width: 363px;
  height: 100%;
  border-left: 1px solid #d4d4d4;
}
.contract-sign-btn {
  position: absolute;
  bottom: 16px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}
/deep/.ant-form-item-label {
  line-height: 20px;
}
/deep/.ant-form-item-required::before {
  content: "";
}
</style>
<style>
body {
  width: 100% !important;
}
</style>
