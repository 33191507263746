import * as rule from "@wlhy-web-lib/rule/esm";

// 开票信息
export const getManagerForm = () => ({
  // name: null,
  signatory: null,
  // placeholder: null,
  placeholder_array: []
});

export const getManagerFormRules = array => {
  let ob = {};
  array.map(item => {
    ob[item.key] = item.key;
    ob[item.key] = [
      rule.required(`${item.value} 不能为空`).message(`${item.value} 不能为空`)
    ];
  });
  // const rules1 = rule.object({
  //   name: [rule.required("企业名称 不能为空").message("企业名称")],
  //   signatory: [rule.required("企业名称 不能为空").message("企业名称 不能为空")]
  // });
  const rules = rule.object(ob);

  return {
    ...rules
  };
};
